import React from "react"
import { Link } from "gatsby"
import { Button } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ResourcePage = () => {

  const resources = [
    {url: 'https://www.duolingo.com/learn', name: 'Spanish'},
    {url: 'https://www.typingclub.com/', name: 'Typing' },
    {url: 'https://www.education.com/', name: 'General' },
  ]
  
  return (
    <Layout>
      <SEO title="Page two" />
      <h1>Hi from the Resource page</h1>
      <p>Welcome to page 2</p>
      {resources.map(res => <div><Button className={'mb-2'} href={res.url} target="_blank">{res.name}</Button></div>)}
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default ResourcePage
